<template>
  <div style="height: 100%; overflow: hidden">
    <div class="text-center py-2 loader" v-show="isLoading">
      <b-spinner></b-spinner>
      <div>
        <strong>Login in corso...</strong>
      </div>
    </div>
    
  <div :class="isLoading ? 'blur-content main-container' : 'main-container'">
    <b-card class="card shadow">
      <div style="text-align: center">
        <img
          src="@/assets/mts-logo.png"
          alt="logo"
          class="logo-login mb-5 mt-2"
        />
      </div>
      <b-form @submit="onSubmit">
        <b-form-group id="fieldset-1" label="Email" label-for="input-1">
          <b-form-input
            id="input-1"
            placeholder="Scrivi il tuo indirizzo email"
            v-model="user.email"
            trim
            required
            name="email"
            oninvalid="this.setCustomValidity('Inserisci una email valida')"
            oninput="setCustomValidity('')"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="password-field" label="Password" label-for="password" class="position-relative d-block">
          <b-form-input
            id="password"
            placeholder="Scrivi la tua password"
            v-model="user.password"
            :type="showPassword ? 'text' : 'password'"
            trim
            required
            autocomplete="on"
            name="password"
            oninvalid="this.setCustomValidity('Inserisci la password')"
            oninput="setCustomValidity('')"
          ></b-form-input>
          <span v-if="showPassword">
            <b-icon
              icon="eye-slash"
              class="position-absolute eye-password-icon"
              shift-v="-4"
              scale="1.7"
              @click="toggleShowPassword()"
            ></b-icon>
          </span>
          <span v-else>
            <b-icon
              class="position-absolute eye-password-icon"
              icon="eye"
              shift-v="-4"
              scale="1.7"
              @click="toggleShowPassword()"
            ></b-icon>
          </span>
        </b-form-group>

        <b-button variant="primary" id="log-in-button" type="submit"
          >Log in</b-button
        >
      </b-form>
      <br />
      <router-link to="/password-recovery">Password dimenticata?</router-link>
    </b-card>
  </div>
</div>
</template>

<script>
import { login, getMe } from "@/services/APIcalls.js";

export default {
  name: "login-component",
  data() {
    return {
      showPassword: false,
      user: {
        email: "",
        password: "",
      },
      error: null,
      isLoading: false,
    };
  },
  computed: {
    state() {
      return this.user.email.length >= 1;
    },
    invalidFeedback() {
      if (this.user.email.length > 0) {
        return "Enter at least 4 characters.";
      }
      return "Please enter something.";
    },
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    async onSubmit(event) {
      event.preventDefault();
      //this.error = null;
      if (this.user.email && this.user.password) {
        this.isLoading = true;

        login(this.user.email, this.user.password)
          .then(() => {
            getMe()
              .then(() => this.$router.push({ name: "home" }))
              .catch(() => {
                this.error = false;
              })
              .finally(() => {
                this.isLoading = false;
              });
            return;
          })
          .catch(() => {
            this.isLoading = false;
            this.error = false;
          });
      }
    },
    deleteErrors() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.card {
  max-width: 400px;
  height: 482px;
  border-style: none;
  padding: 0 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media (max-width: 436px) {
  .card {
    background-color: #F5F5FC;
  }
  .shadow {
    box-shadow: none !important;
  }
}

#log-in-button {
  position: absolute;
  right: 24px;
  bottom: 24px;
}
a {
  position: absolute;
  bottom: -42px;
  left: 50%;
  transform: translateX(-50%);
  padding: 14px 0;
}

.form-group {
  padding-bottom: 20px;
}
.btn {
  width: 150px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 1000;
  font-size: 1.3em;
}

.blur-content {
  height: 100vh;
}

.eye-password-icon {
  cursor: pointer;
  right: 0px;
  margin-right: 22px;
  top: 32px;
}
</style>
